import firebase from 'firebase/app';
import { useCallback, useContext } from 'react';
import { config } from '../../config';
import { UserContext } from '../../contexts';

export const Home = () => {
  const currentUser = useContext(UserContext);

  const secureFetch = useCallback(async () => {
    const token = await currentUser.getIdToken();

    const response = await fetch(`${config.bffUrl}/secure-ping`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const text = await response.text();
      window.alert(text);
    }
  }, [currentUser]);

  return (
    <>
      <h2>Welcome {currentUser.displayName}</h2>
      <button onClick={secureFetch}>Secure Fetch</button>&nbsp;
      <button onClick={() => firebase.auth().signOut()}>Sign-out</button>
    </>
  );
};
