import firebase from 'firebase/app';
import 'firebase/auth';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Home } from './components/home';
import { Login } from './components/login';
import { UserContext } from './contexts';

export const App = () => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>();

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setCurrentUser(user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (currentUser === undefined) {
    return null;
  }
  if (currentUser === null) {
    return <Login />;
  }

  return (
    <UserContext.Provider value={currentUser}>
      <Router>
        <h1>My App</h1>
        <Route exact path="/">
          <Home />
        </Route>
      </Router>
    </UserContext.Provider>
  );
};
